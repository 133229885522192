import get from 'lodash/get'

export const isBrowser = typeof window !== 'undefined'

export const getUser = () =>
  isBrowser && window.localStorage.getItem('user')
    ? JSON.parse(window.localStorage.getItem('user'))
    : {}

export const setUser = user => {
  window.localStorage.setItem('user', JSON.stringify(user))
}

export const isLoggedIn = () => {
  const user = getUser()
  return !!user.apiKey
}

export const logout = callback => {
  setUser({})
  callback()
}

export const setCurrentCompanyKey = name => {
  setUser({ ...getUser(), currentCompany: name })
}
export const getCurrentCompanyKey = () => {
  return get(getUser(), 'currentCompany')
}

export const getCompanyKeys = isExample => {
  if (isExample) {
    return ['example-url']
  }
  const companyKeys = get(getUser(), 'displayName')
  if (companyKeys) {
    return companyKeys.split(',')
  }
  return
}
