import React, { useState } from 'react'
import Button from 'react-bootstrap/button'
import Form from 'react-bootstrap/form'
import { navigate } from 'gatsby'
import firebase from 'src/components/firebase/init'
import get from 'lodash/get'
import head from 'lodash/head'
import includes from 'lodash/includes'
// custom
import {
  getUser,
  setUser,
  setCurrentCompanyKey,
} from 'src/components/auth/utils'

function FormUser({ type, items }) {
  const [loading, setLoading] = useState(false)

  const handleSubmit = (event, type) => {
    const email = get(event, 'target.email.value')
    const password = get(event, 'target.password.value')
    const displayName = get(event, 'target.displayName.value')

    setLoading(true)
    event.preventDefault()
    event.stopPropagation()

    // 1. login
    if (type === 'login') {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(function (result) {
          const withCompanies = includes(result.user?.displayName, ',')
          setUser(result.user)
          if (get(result, 'user.displayName')) {
            if (withCompanies) {
              setCurrentCompanyKey(head(result.user.displayName.split(',')))
            } else {
              setCurrentCompanyKey(result.user.displayName)
            }
          } else {
            setCurrentCompanyKey('example-url')
          }
          if (withCompanies) {
            navigate('/app/home')
            return
          }
          // jump into unique company admin
          navigate('/app/admin')
          setLoading(false)
        })
        .catch(function (error) {
          console.warn(error)
          setLoading(false)
        })
    }
    // 2. update user
    if (type === 'update') {
      let payload = { email, displayName }
      if (password) {
        payload = { ...payload, ...password }
      }
      firebase
        .auth()
        .currentUser.updateProfile(payload)
        .then(function (result) {
          setLoading(false)
          alert('updated profile')
        })
        .catch(function (error) {
          console.warn(error)
          setLoading(false)
        })
    }
  }
  const getDefaultValue = (name) => {
    if (name === 'password') {
      return null
    }

    return get(getUser(), name, null)
  }

  return (
    <Form onSubmit={(event) => handleSubmit(event, type)} className="mt-5">
      {items.map((item, index) => (
        <Form.Group key={index}>
          <Form.Label className="text-white">{item.label}</Form.Label>
          <Form.Control
            type={item.type}
            name={item.name}
            placeholder={item.placeholder}
            autoComplete={item.autoComplete}
            defaultValue={getDefaultValue(item.name)}
            disabled={loading}
          />
        </Form.Group>
      ))}
      <Button
        type="submit"
        variant="primary"
        className="mt-3"
        disabled={loading}
      >
        {loading ? '⏳ Loading ...' : 'Submit'}
      </Button>
    </Form>
  )
}

export default FormUser
