export const userConfig = [
  {
    name: 'email',
    type: 'email',
    label: 'Email address',
    placeholder: 'Enter email',
    autoComplete: 'email',
  },
  {
    name: 'password',
    type: 'password',
    label: 'Password',
    placeholder: 'Enter password',
    autoComplete: 'current-password',
  },
  {
    name: 'displayName',
    type: 'text',
    label: 'company',
    placeholder: 'Enter company pahthame (e.g. `example-url`)',
    autoComplete: 'company',
  },
]
